import React, { useMemo, useState } from "react";
import "./home-styles.css";
import statisticsCartIcon from "../../assets/icons/statisticsCartIcon.svg";
import statisticsTreeLeafIcon from "../../assets/icons/statisticsTreeLeafIcon.svg";
import statisticsStartIcon from "../../assets/icons/statisticsStartIcon.svg";
import { DateRangePicker } from "../../components/date-range-picker/DateRangePicker";
import { StatsCard } from "../../components/stats-card/StastCard";
import { TableFilters } from "../../components/table-filters/TableFilters";
import { Table } from "../../components/table/Table";
import useSearchAndSort from "../../hooks/useSearchAndSort";

const Home = () => {
  const initialData = useMemo(
    () => [
      {
        idTrip: 142,
        dateTrip: "Nov 16, 2024",
        enterpriseNameTrip: "GUDD Consulting S.A. de. C.V.",
        passengersTrip: "5/5",
        totalDistanceTrip: "45 km",
        reducedCo2Trip: "0.3 t",
        tripStatus: "En Curso",
      },
      {
        idTrip: 142,
        dateTrip: "Oct 23, 2024",
        enterpriseNameTrip: "Empresa S.A. de C.V.",
        passengersTrip: "2/5",
        totalDistanceTrip: "45 km",
        reducedCo2Trip: "0.3 t",
        tripStatus: "Terminado",
      },
      {
        idTrip: 142,
        dateTrip: "Nov 16, 2024",
        enterpriseNameTrip: "GUDD Consulting S.A. de. C.V.",
        passengersTrip: "5/5",
        totalDistanceTrip: "45 km",
        reducedCo2Trip: "0.3 t",
        tripStatus: "En Curso",
      },
      {
        idTrip: 142,
        dateTrip: "Oct 23, 2024",
        enterpriseNameTrip: "Empresa S.A. de C.V.",
        passengersTrip: "2/5",
        totalDistanceTrip: "45 km",
        reducedCo2Trip: "0.3 t",
        tripStatus: "Terminado",
      },
    ],
    []
  );

  const [sortPriority, setSortPriority] = useState([]);
  const [sortDirections, setSortDirections] = useState({
    enterpriseNameTrip: true,
    reducedCo2Trip: true,
  });

  const customComparators = useMemo(
    () => ({
      reducedCo2Trip: (a, b) => {
        const valueA = parseFloat(a.slice(0, 3));
        const valueB = parseFloat(b.slice(0, 3));
        return valueA - valueB;
      },
    }),
    []
  );

  const [searchQuery, setSearchQuery, sortedData] = useSearchAndSort(
    initialData,
    "",
    sortPriority,
    sortDirections,
    ["enterpriseNameTrip", "reducedCo2Trip"],
    customComparators
  );

  const handleSort = (key) => {
    const newSortPriority = sortPriority.includes(key)
      ? sortPriority.filter((item) => item !== key)
      : [...sortPriority, key];

    setSortPriority(newSortPriority);
    setSortDirections((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const columns = [
    {
      label: "ID",
      className: "column-start",
      field: "idTrip",
    },
    {
      label: "Fecha",
      className: "column-start",
      field: "dateTrip",
    },
    {
      label: "Empresa",
      className: "column-name",
      field: "enterpriseNameTrip",
    },
    {
      label: "Pasajeros",
      className: "column-start",
      field: "passengersTrip",
    },
    {
      label: "Distancia Total",
      className: "column-start",
      field: "totalDistanceTrip",
    },
    {
      label: "CO2 Reducido",
      className: "column-start",
      field: "reducedCo2Trip",
    },
    {
      label: "Estado",
      className: "column-start",
      field: "tripStatus",
    },
  ];

  const filters = [
    {
      field: "enterpriseNameTrip",
      type: "string",
      label: "Empresas",
    },
    {
      field: "reducedCo2Trip",
      type: "shortString",
      label: "Emisiones",
    },
  ];

  return (
    <div>
      <div className="companies-header">
        <h2>Estadísticas Globales</h2>
        <DateRangePicker />
      </div>
      <div className="statistics-card-container">
        <StatsCard
          icon={statisticsCartIcon}
          title={"Viajes Realizados"}
          value={484}
          figureDescription={"viajes"}
          three={true}
        />
        <StatsCard
          icon={statisticsTreeLeafIcon}
          title={"Ahorro de CO2"}
          value={13.8}
          figureDescription={"toneladas"}
          three={true}
        />
        <StatsCard
          icon={statisticsStartIcon}
          title={"Lider CO2"}
          figureDescription={"GUUD Consulting"}
          three={true}
        />
      </div>
      <TableFilters
        filters={filters}
        sortPriority={sortPriority}
        sortDirections={sortDirections}
        handleSort={handleSort}
        handleSearch={setSearchQuery}
        searchQuery={searchQuery}
        tableDataChangeFilters={true}
      />
      <div className="company-table-container">
        <Table columns={columns} data={sortedData} />
      </div>
    </div>
  );
};

export { Home };
