// apiSlice.js
// Configuración base de la API y manejo de autenticación
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { setAccessToken, logOut } from '../features/auth/authSlice';

const baseQuery = fetchBaseQuery({
    baseUrl: 'http://api.amvi.app:3000/api',
    credentials: 'include',
    prepareHeaders: (headers, { getState }) => {
        const token = getState().auth.accessToken;
        if (token) {
            headers.set('authorization', `Bearer ${token}`);
        }
        return headers;
    },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);

    if (result?.error?.status === 403) {
        const refreshResult = await baseQuery(
            { url: 'auth/token', method: 'POST' },
            api,
            extraOptions
        );

        if (refreshResult.data) {
            api.dispatch(setAccessToken(refreshResult.data.accessToken));
            result = await baseQuery(args, api, extraOptions);
        } else {
            await baseQuery(
                { url: 'auth/logout', method: 'POST' },
                api,
                extraOptions
            );
            api.dispatch(logOut());
        }
    }
    return result;
};

export const apiSlice = createApi({
    baseQuery: baseQueryWithReauth,
    tagTypes: ['Enterprise', 'User', 'Employee'],
    endpoints: (builder) => ({}),
});
