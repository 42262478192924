// features/employee/employeeSlice.js
// Manejo del estado del empleado
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    employeeId: null,
};

export const employeeSlice = createSlice({
    name: 'employee',
    initialState,
    reducers: {
        setEmployee: (state, action) => {
            state.employeeId = action.payload;
        },
    },
});

export const { setEmployee } = employeeSlice.actions;
export default employeeSlice.reducer;
export const selectCurrentEmployee = (state) => state.employee.employeeId;
