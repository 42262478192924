import React from "react";
import "./filter-button-styles.css";
import downArrowIcon from "../../assets/icons/grayDownArrowIcon.svg";

const FilterButton = ({ label, active, onClick }) => {
    return (
        <div
            className={`filter-container ${active ? "active" : ""}`}
            onClick={onClick}
        >
            <label>{label}</label>
            <img src={downArrowIcon} alt="arrow" />
        </div>
    );
};

export { FilterButton };
