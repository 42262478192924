import React from "react";
import "./nav-bar-styles.css";
import Logo from "../../assets/icons/amviLogoHorizontal.svg";
import LanguageIcon from "../../assets/icons/languageIcon.svg";
import { useLocation } from "react-router-dom";
import NotificationIcon from "../../assets/icons/notificationIcon.svg";
import DownArrowIcon from "../../assets/icons/downArrowIcon.svg";

const NavBar = () => {
  const location = useLocation();
  return (
    <div className="navbar-container">
      <img src={Logo} alt="amviHorizontal" />

      {location.pathname === "/" ? (
        <div className="language-container">
          <img src={LanguageIcon} alt="language-icon" />
          <span>ES</span>
        </div>
      ) : (
        <div className="notifcation-icon-container-and-profile-options">
          <img src={NotificationIcon} alt="notification-icon" />
          <div className="profile-icon-container">
            <div className="profile-circle" />
            <img src={DownArrowIcon} alt="down-arrow-icon" />
          </div>
        </div>
      )}
    </div>
  );
};

export { NavBar };
