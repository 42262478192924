import React, { useEffect, useMemo, useState } from "react";
import "./companies-styles.css";
import { DateRangePicker } from "../../components/date-range-picker/DateRangePicker";
import businessIcon from "../../assets/icons/businessIcon.svg";
import { StatsCard } from "../../components/stats-card/StastCard";
import employeeIcon from "../../assets/icons/employeeIcon.svg";
import { Table } from "../../components/table/Table";
import { TableFilters } from "../../components/table-filters/TableFilters";
import texts from "../../texts.json";
import useSearchAndSort from "../../hooks/useSearchAndSort";
import {
  useEnterpriseQuantityQuery,
  useGetEnterpriseNameQuery,
} from "../../features/enterprise/enterpriseApiSlice";
import { useGetQuantityEmployeeQuery } from "../../features/employee/employeeApiSlice";

const Companies = () => {
  const { data: dataQuantityEnterprise, isLoading } =
    useEnterpriseQuantityQuery();
  const { data: dataQuantityEmployee, isQuantity } =
    useGetQuantityEmployeeQuery();
  const { data: enterprisesNames } = useGetEnterpriseNameQuery();
  const [quantityCompanies, setQuantityCompanies] = useState(0);
  const [quantityEmployees, setQuantityEmployees] = useState(0);

  useEffect(() => {
    const dataCompanies = isLoading ? 0 : dataQuantityEnterprise?.total;
    const countC = parseInt(dataCompanies, 10);
    setQuantityCompanies(countC);
    const dataEmployees = isQuantity ? 0 : dataQuantityEmployee?.total;
    const countE = parseInt(dataEmployees, 10);
    setQuantityEmployees(countE);
  }, [dataQuantityEnterprise, dataQuantityEmployee, isLoading, isQuantity]);

  const initialData = useMemo(() => {
    return enterprisesNames?.map((company) => ({
      nombre: company.enterpriseName,
      empleados: 1256,
      viajesTotales: 12246,
      viajesEnCurso: 549,
      emisionesAhorradas: "4.2t CO2",
    }));
  }, [enterprisesNames]);

  const [sortPriority, setSortPriority] = useState([]);
  const [sortDirections, setSortDirections] = useState({
    nombre: true,
    empleados: true,
    emisionesAhorradas: true,
  });

  const customComparators = useMemo(
    () => ({
      emisionesAhorradas: (a, b) => {
        const valueA = parseFloat(a.slice(0, 3));
        const valueB = parseFloat(b.slice(0, 3));
        return valueA - valueB;
      },
    }),
    []
  );

  const [searchQuery, setSearchQuery, sortedData] = useSearchAndSort(
    initialData,
    "",
    sortPriority,
    sortDirections,
    ["nombre", "empleados", "emisionesAhorradas"],
    customComparators
  );

  const handleSort = (key) => {
    const newSortPriority = sortPriority.includes(key)
      ? sortPriority.filter((item) => item !== key)
      : [...sortPriority, key];

    setSortPriority(newSortPriority);
    setSortDirections((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const columns = [
    {
      label: texts.components.table.name,
      className: "column-name",
      field: "nombre",
    },
    {
      label: texts.components.table.employees,
      className: "column-right",
      field: "empleados",
    },
    {
      label: texts.components.table.totalTrips,
      className: "column-right",
      field: "viajesTotales",
    },
    {
      label: texts.components.table.currentTrips,
      className: "column-right",
      field: "viajesEnCurso",
    },
    {
      label: texts.components.table.savedEmissions,
      className: "column-center",
      field: "emisionesAhorradas",
    },
  ];

  const filters = [
    {
      field: "nombre",
      type: "string",
      label: "Empresas",
    },
    {
      field: "empleados",
      type: "number",
      label: "Empleados",
    },
    {
      field: "emisionesAhorradas",
      type: "shortString",
      label: "Emisiones",
    },
  ];

  const [numberCompanies] = useState(128);

  return (
    <div>
      <div className="companies-header">
        <h2>{texts.views.companies.title}</h2>
        <DateRangePicker />
      </div>
      <div className="statistics-card-container">
        <StatsCard
          icon={businessIcon}
          title={texts.views.companies.businessCardTitle}
          value={quantityCompanies}
          figureDescription={texts.views.companies.businessCardDescription}
        />
        <StatsCard
          icon={employeeIcon}
          title={texts.views.companies.employeeCardTitle}
          value={quantityEmployees}
          figureDescription={texts.views.companies.employeeCardDescription}
        />
      </div>
      <TableFilters
        filters={filters}
        sortPriority={sortPriority}
        sortDirections={sortDirections}
        handleSort={handleSort}
        handleSearch={setSearchQuery}
        searchQuery={searchQuery}
      />
      <div className="company-table-container">
        <Table columns={columns} data={sortedData} />
      </div>
    </div>
  );
};

export { Companies };
