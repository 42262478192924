import React from "react";
import "./blue-input-form.css";

const BlueInputForm = ({ label, value, onChange, type, id }) => {
  return (
    <div className="blue-input-container">
      <label className="blue-input-label">{label}</label>
      <input
        className="blue-input"
        value={value}
        onChange={onChange}
        type={type}
        id={id}
        required
      />
    </div>
  );
};

export { BlueInputForm };
