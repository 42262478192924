import React, { useState, useEffect } from "react";
import "./stats-card-styles.css";

const StatsCard = ({ icon, title, value, figureDescription, three }) => {
  const [currentValue, setCurrentValue] = useState(0);

  if (value === undefined) {
    value = "";
  }

  const animateValue = (targetValue, setter, isInteger) => {
    const duration = 1000;
    const startValue = targetValue - 3; // Restar 3 al valor inicial
    const endValue = targetValue;
    const step = 1; // Incremento de 1 en 1

    let currentValue = startValue;

    const interval = setInterval(() => {
      if (currentValue < endValue) {
        currentValue += step;
        if (isInteger) {
          setter(Math.floor(currentValue));
        } else {
          setter(parseFloat(currentValue.toFixed(2)));
        }
      } else {
        clearInterval(interval);
        setter(endValue); // Asegurarse de terminar exactamente en el valor final
      }
    }, duration / (endValue - startValue)); // Calcular el tiempo por cada incremento
  };

  useEffect(() => {
    animateValue(value, setCurrentValue, true);
  }, [value]);

  const renderDigits = (number) => {
    const digits = String(number).split("");
    return (
      <div style={{ display: "flex", gap: "0.1em" }}>
        {digits.map((digit, index) => (
          <div
            key={index}
            className="digit-container"
            style={{
              overflow: "hidden",
              width: "0.5em", // Asegura que todos los dígitos tengan el mismo ancho
              height: "1.5em",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              alignItems: "center",
              position: "relative",
            }}
          >
            <div
              className="digit"
              style={{
                position: "absolute",
                animation: "slideUp 0.3s ease-out",
              }}
            >
              {digit}
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="statistics-card" style={{ padding: three && "15px 20px" }}>
      <div className="statistics-card-content">
        <img src={icon} alt="statistics-card-icon" />
        <div className="statistics-card-title">
          <label>{title}</label>
          <p>
            {renderDigits(currentValue) ? (
              <>
                {renderDigits(currentValue)}
                {figureDescription && <span>&nbsp;</span>}
                {figureDescription}
              </>
            ) : (
              figureDescription
            )}
          </p>
        </div>
      </div>
      <span className="statistics-card-percentage"></span>
      <style>
        {`
          @keyframes slideUp {
            from {
              transform: translateY(100%);
              opacity: 0;
            }
            to {
              transform: translateY(0);
              opacity: 1;
            }
          }
        `}
      </style>
    </div>
  );
};

export { StatsCard };
