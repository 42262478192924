import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import calendarIcon from "../../assets/icons/calendarIcon.svg";
import "./date-range-picker-styles.css";
const DateRangePicker = () => {
    const [dates, setDates] = useState([null, null]);
    const [isOpen, setIsOpen] = useState(false);

    const handleDateChange = (dates) => {
        setDates(dates);
        if (dates[0] && dates[1]) {
            setIsOpen(false);
        }
    };

    const handleTogglePicker = () => {
        setIsOpen(!isOpen);
    };

    const formatDate = (date) => {
        if (!date) return '';
        const formattedDate = format(date, 'MMMM dd yyyy', { locale: es });
        const [month, ...rest] = formattedDate.split(' ');
        return [month.charAt(0).toUpperCase() + month.slice(1), ...rest].join(' ');
    };

    const renderFormattedRange = () => {
        const [startDate, endDate] = dates;
        if (startDate && endDate) {
            return `${formatDate(startDate)} a ${formatDate(endDate)}`;
        }
        return 'Octubre 01 2024 a Octubre 29 2024';
    };

    return (
        <div>
            <div onClick={handleTogglePicker} className="companies-header-date">
                <img src={calendarIcon} alt="calendarIcon" className="calendar-icon" />
                {renderFormattedRange()}
            </div>

            {isOpen && (
                <div className="date-range-picker-container">
                    <DatePicker
                        selected={dates[0]}
                        onChange={handleDateChange}
                        selectsRange
                        startDate={dates[0]}
                        endDate={dates[1]}
                        dateFormat="MMMM dd yyyy"
                        placeholderText="Selecciona un rango de fechas"
                        inline
                        locale={es}
                    />
                </div>
            )}
        </div>
    );
};

export { DateRangePicker };
