// enterpriseApiSlice.js
import { apiSlice } from '../../api/apiSlice';

export const enterpriseApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        EnterpriseQuantity: builder.query({
            query: () => "/enterprise/count",
            keepUnusedDataFor: 60,
            providesTags: ['Enterprise'],
        }),
        getEnterpriseName: builder.query({
            query: () => "/enterprise/names",
            keepUnusedDataFor: 60,
            providesTags: ['Enterprise'],
        }),
    })
});

export const {
    useEnterpriseQuantityQuery,
    useGetEnterpriseNameQuery
} = enterpriseApiSlice;
