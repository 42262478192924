// employeeApiSlice.js
import { apiSlice } from '../../api/apiSlice';

export const employeeApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getQuantityEmployee: builder.query({
            query: () => "/employee/quantity",
            keepUnusedDataFor: 60,
            providesTags: ['Employee'],
        }),
    })
});

export const {
    useGetQuantityEmployeeQuery
} = employeeApiSlice;