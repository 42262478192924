import React, { useState } from "react";
import { FilterButton } from "../filter-button/FilterButton";
import { SearchInput } from "../search-input/SearchInput";
import "./table-filters-styles.css";

const TableFilters = ({
  filters,
  sortPriority,
  sortDirections,
  handleSort,
  handleSearch,
  searchQuery,
  tableDataChangeFilters,
}) => {
  const [activeDiv, setActiveDiv] = useState("div1");
  return (
    <>
      {tableDataChangeFilters && (
        <div className="change-filter-container">
          <div
            className={`${activeDiv === "div1" ? "active" : ""}`}
            onClick={() => setActiveDiv("div1")}
          >
            Viajes
          </div>
          <div
            className={`${activeDiv === "div2" ? "active" : ""}`}
            onClick={() => setActiveDiv("div2")}
          >
            Próximos Viajes
          </div>
        </div>
      )}
      <div
        className="company-table-filter-container"
        style={{ marginTop: tableDataChangeFilters && 0 }}
      >
        <label>Filtros</label>
        <div className="container-different-filters">
          {filters.map(({ field, type, label }) => (
            <FilterButton
              key={field}
              label={label}
              active={sortPriority.includes(field)}
              onClick={() => handleSort(field, type)}
            />
          ))}
          <SearchInput
            value={searchQuery}
            onChange={handleSearch}
            placeholder="Buscar empresa"
          />
        </div>
      </div>
    </>
  );
};

export { TableFilters };
