import { useState, useEffect } from "react";

const useSearchAndSort = (
  initialData,
  query,
  sortPriority,
  sortDirections,
  searchFields = [],
  customSortComparators = {}
) => {
  const [searchQuery, setSearchQuery] = useState(query);
  const [sortedData, setSortedData] = useState([]);

  useEffect(() => {
    const filteredData = [...(initialData || [])]
      .filter((item) => {
        // Si no hay campos de búsqueda especificados, devuelve todos los datos.
        if (searchFields.length === 0) return true;

        // Verifica dinámicamente los campos para encontrar coincidencias.
        return searchFields.some((field) =>
          item[field]
            ?.toString()
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
        );
      })
      .sort((a, b) => {
        for (const sortKey of sortPriority) {
          const dir = sortDirections[sortKey] ? 1 : 1;

          // Verifica si hay un comparador personalizado para este campo.
          if (customSortComparators[sortKey]) {
            const comparison =
              customSortComparators[sortKey](a[sortKey], b[sortKey]) * dir;
            if (comparison !== 0) return comparison;
          }

          // Comparación para strings.
          if (
            typeof a[sortKey] === "string" &&
            typeof b[sortKey] === "string"
          ) {
            const comparison = a[sortKey].localeCompare(b[sortKey]) * dir;
            if (comparison !== 0) return comparison;
          }

          // Comparación para números.
          if (
            typeof a[sortKey] === "number" &&
            typeof b[sortKey] === "number"
          ) {
            const comparison = (a[sortKey] - b[sortKey]) * dir;
            if (comparison !== 0) return comparison;
          }
        }
        return 0;
      });

    setSortedData(filteredData);
  }, [
    initialData,
    searchQuery,
    sortPriority,
    sortDirections,
    customSortComparators,
  ]);

  return [searchQuery, setSearchQuery, sortedData];
};

export default useSearchAndSort;
