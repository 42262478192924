// features/enterprise/enterpriseSlice.js
// Manejo del estado de la cantidad de empresas
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    enterpriseQuantity: 0,
};

export const enterpriseSlice = createSlice({
    name: 'enterprise',
    initialState,
    reducers: {
        setEnterpriseQuantity: (state, action) => {
            state.enterpriseQuantity = action.payload;
        },
    },
});

export const { setEnterpriseQuantity } = enterpriseSlice.actions;
export default enterpriseSlice.reducer;
export const totalEnterpriseQuantity = (state) => state.enterprise.enterpriseQuantity;
