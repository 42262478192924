import React from "react";
import "./side-bar-styles.css";
import HomeIcon from "../../assets/icons/sidebarHomeIcon.svg";
import CompaniesIcon from "../../assets/icons/sidebarCompaniesIcon.svg";
import { useLocation, useNavigate } from "react-router-dom";

const SideBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <div className="sidebar-container">
      <img
        src={HomeIcon}
        alt="sidebar-home-icon"
        className={location.pathname === "/home" ? "active" : ""}
        onClick={() => navigate("/home")}
      />
      <img
        src={CompaniesIcon}
        alt="sidebar-companies-icon"
        className={location.pathname === "/companies" ? "active" : ""}
        onClick={() => navigate("/companies")}
      />
    </div>
  );
};

export { SideBar };
