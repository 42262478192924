// features/auth/authApiSlice.js
// Endpoints para manejo de autenticación
import { apiSlice } from '../../api/apiSlice';

export const authApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        login: builder.mutation({
            query: (credentials) => ({
                url: '/auth/login',
                method: 'POST',
                body: credentials,
            }),
            invalidatesTags: ["Enterprise", "User"]
        }),
        logout: builder.mutation({
            query: () => ({
                url: '/auth/logout',
                method: 'POST',
            }),
        }),
        signup: builder.mutation({
            query: (body) => ({
                url: '/auth/register',
                method: 'POST',
                body,
            }),
            invalidatesTags: ["Enterprise", "User"]
        }),
    }),
});

export const {
    useLoginMutation,
    useLogoutMutation,
    useSignupMutation,
} = authApiSlice;
