import React from "react";
import ReactDOM from "react-dom/client";
import { useRoutes, BrowserRouter } from "react-router-dom";
import "./index.css";
import { Provider } from "react-redux";
import { store, persistor } from "./features/store";
import App from "./App";
// import RequireAuth from "./features/auth/RequireAuth";
import { Login } from "./account/login/login";
import { Home } from "./views/home/home";
import { Companies } from "./views/companies/Companies";
import { PersistGate } from "redux-persist/integration/react";

const AppRoutes = () => {
  let routes = useRoutes([
    { path: "/", element: <Login /> },
    // {
    //   element: <RequireAuth />,
    //   children: [
    { path: "/home", element: <Home /> },
    { path: "/companies", element: <Companies /> },
    //   ]
    // }
  ]);
  return routes;
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <App>
            <AppRoutes />
          </App>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode >
);

