import React from "react";
import { useLocation } from "react-router-dom";
import "./App.css";
import { NavBar } from "./components/nav-bar/NavBar";
import { SideBar } from "./components/side-bar/SideBar";
import { Footer } from "./components/footer/Footer";

function App({ children }) {
  const location = useLocation();

  const isAuthRoute = ["/"].includes(location.pathname);

  return (
    <>
      <NavBar />
      <div className={!isAuthRoute ? "main-container" : ""}>
        {!isAuthRoute && <SideBar />}
        <div className="main-margin">{children}</div>
      </div>
      <Footer />
    </>
  );
}

export default App;
