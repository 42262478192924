import React from "react";
import "./search-input-styles.css";
import searchIcon from "../../assets/icons/searchIcon.svg";

const SearchInput = ({ value, onChange, placeholder }) => {
    return (
        <div className="search-bar-filter-container">
            <img src={searchIcon} alt="search" />
            <input
                type="text"
                value={value}
                onChange={(e) => onChange(e.target.value)}
                placeholder={placeholder || "Buscar..."}
            />
        </div>
    );
};

export { SearchInput };
