import React, { useState } from "react";
import "./login-styles.css";
import LoginImage from "../../assets/images/carpool-pana.png";
import HorizontalLogoWithSlogan from "../../assets/images/horizontalLogoWithSlogan.png";
import { BlueInputForm } from "../../components/blue-input-form/BlueInputForm";
import { GreenActionButton } from "../../components/green-action-button/GreenActionButton";
import { useNavigate } from "react-router-dom";
import texts from "../../texts.json";
import { useDispatch } from "react-redux";
import { useLoginMutation } from "../../features/auth/authApiSlice";
import { setAccessToken } from "../../features/auth/authSlice";

const Login = () => {
  const textsLogin = texts.account.login;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [login, { isLoading }] = useLoginMutation();
  const [error, setError] = useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const userData = await login({
        username: email,
        password: password,
      }).unwrap();
      dispatch(setAccessToken(userData.token));
      navigate("/home");
    } catch (err) {
      if (err.status === 401) {
        setError("Invalid credentials. Please try again.");
      } else {
        setError("An unexpected error occurred. Please try again later.");
      }
    }
  };

  const [credentials, setCredentials] = useState({
    emailOrPhone: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials({
      ...credentials,
      [name]: value,
    });
  };
  return (
    <div className="login-container">
      <div className="login-form-container">
        <div className="logo-and-slogan-container">
          <img
            src={HorizontalLogoWithSlogan}
            alt="horizontal-logo-with-slogan"
          />
          <h3>{texts.common.slogan}</h3>
        </div>
        <h2>{textsLogin.formTilte}</h2>
        {error && <div className="error-message">{error}</div>}
        <div className="login-log-container">
          <form onSubmit={handleSubmit}>
            <BlueInputForm
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              label={textsLogin.emailLabel}
            />
            <BlueInputForm
              label={textsLogin.passwordLabel}
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <div className="reset-password-link-container">
              <label className="reset-password-link-label">
                {textsLogin.resetPassword}
              </label>
            </div>
            <GreenActionButton
              type="submit"
              text={isLoading ? "Iniciando sesión..." : textsLogin.button}
              disabled={isLoading}
            />
          </form>
        </div>
      </div>
      <div className="login-image-container">
        <img src={LoginImage} alt="login-image" className="login-image-size" />
      </div>
    </div>
  );
};

export { Login };
