import React from "react";
import "./green-action-button-styles.css";

const GreenActionButton = ({ text, onClick, type, disabled }) => {
  return (
    <button className="green-action-button" onClick={onClick} type={type} disabled={disabled}>
      {text}
    </button>
  );
};

export { GreenActionButton };
